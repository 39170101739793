import cn from 'classnames'
import FocusTrap from 'focus-trap-react'
import PropTypes from 'prop-types'
import React, {
  KeyboardEvent,
  KeyboardEventHandler,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { createPortal } from 'react-dom'
import { usePopper } from 'react-popper'

import { useTranslation } from '@mc/i18n'
import { BREAKPOINTS } from '@mc/media'

import Backdrop from '../Backdrop'
import ClickOutside from '../ClickOutside'
import { PROP_TYPE_CHILDREN } from '../constants'
import { DropdownContext } from '../Dropdown'
import { getClosest } from '../helpers'
import DropdownClose from './DropdownClose'

const THEME_DARK = 'dark'
const THEME_LIGHT = 'light'

export interface DropdownContentControlledProps
  extends React.HTMLAttributes<HTMLDivElement> {
  appendToBody?: boolean
  children: React.ReactElement | React.ReactNode
  className?: string
  onClose?: (source: string, event: Event | React.SyntheticEvent) => void
  show?: boolean
  style?: React.CSSProperties
}

const DropdownContentControlled = ({
  appendToBody = false,
  children,
  className,
  onClose = () => {},
  show = false,
  style = undefined,
  ...props
}: DropdownContentControlledProps) => {
  const {
    ariaControls,
    dropdownElement,
    setDropdownElement,
    toggleElement,
    placement,
    fixed,
    focusTrap,
  } = useContext(DropdownContext)
  const [themeChecked, setThemeChecked] = useState(false)
  const [theme, setTheme] = useState(THEME_DARK)
  const [shouldTrap, setShouldTrap] = useState(false)
  const placeholderRef = useRef<HTMLDivElement>(null)
  const [mobile, setMobile] = useState(false)
  const { styles, attributes } = usePopper(toggleElement, dropdownElement, {
    placement,
    // This seems wrong, but it's not. Our "fixed" prop is for whether or not the
    // component usage is in a case where it's container is fixed. We also need to
    // set this for mobile, as it's relative to the page (not the trigger container)
    strategy: !fixed || mobile ? 'fixed' : 'absolute',
    modifiers: [
      {
        name: 'flip',
        enabled: true,
      },
    ],
  })
  const { t } = useTranslation('@mc/design-system')

  useEffect(() => {
    if (typeof window === 'undefined') return () => {}

    setMobile(window.innerWidth < BREAKPOINTS.SM)

    const handleResize = () => {
      setMobile(window.innerWidth < BREAKPOINTS.SM)
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [setMobile])

  useEffect(() => {
    if (typeof window === 'undefined') return

    setShouldTrap(
      (show && window.innerWidth < BREAKPOINTS.SM && focusTrap) || false,
    )
  }, [show, focusTrap, setShouldTrap])

  useEffect(() => {
    const themeEl = getClosest(placeholderRef?.current, '[class*="mc-theme-"]')

    // TODO: does this even work?
    if (themeEl?.className?.includes('mc-theme-light')) {
      setTheme(THEME_LIGHT)
    }

    setThemeChecked(true)
  }, [])

  const renderToLocation = (content: React.ReactNode) => {
    if (!show) return null
    const renderTarget: Element = document.fullscreenElement
      ? document.fullscreenElement
      : document.body
    return appendToBody ? createPortal(content, renderTarget) : content
  }

  const handleClose = useCallback(
    (source: string) => (event: Event | React.SyntheticEvent) => {
      onClose(source, event)
    },
    [onClose],
  )

  const handleKeydown =
    (
      toggle: (type: string, e: Event | React.SyntheticEvent) => void,
    ): KeyboardEventHandler<HTMLDivElement> =>
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Escape') {
        toggle('toggle', event)
      }
    }

  const classes = cn({
    'mc-dropdown': true,
    'mc-dropdown--active': show,
    'mc-theme-light': theme === THEME_LIGHT,
    [className ?? '']: className,
  })
  const a11yProps = {
    'aria-labelledby': 'mobile-dropdown',
    role: 'dialog',
    'aria-modal': mobile,
  }

  return (
    <>
      {!themeChecked && <div ref={placeholderRef} />}

      {renderToLocation(
        <>
          <ClickOutside
            externalInsideElements={[
              { current: dropdownElement },
              { current: toggleElement },
            ]}
            onClickOutside={handleClose('outside')}
          />
          <div
            className={classes}
            ref={setDropdownElement}
            style={{ ...styles.popper, ...style }}
            {...attributes.popper}
            {...a11yProps}
            {...props}
          >
            <h1 id='mobile-dropdown' className='mc-sr-only'>
              {t('dropdown.mobileDropdownSrOnlyText')}
            </h1>
            <Backdrop className='mc-dropdown__backdrop' kind={'dark'} />
            <FocusTrap
              active={shouldTrap}
              focusTrapOptions={{
                escapeDeactivates: false,
              }}
            >
              <div className='mc-dropdown__content-container'>
                <div className='d-block d-sm-none mc-dropdown__close'>
                  <DropdownClose onClick={handleClose('close')} />
                </div>

                <div
                  id={ariaControls}
                  className='mc-dropdown__content'
                  onKeyDown={handleKeydown(onClose)}
                >
                  {children}
                </div>
              </div>
            </FocusTrap>
          </div>
        </>,
      )}
    </>
  )
}

DropdownContentControlled.propTypes = {
  appendToBody: PropTypes.bool,
  children: PROP_TYPE_CHILDREN,
  className: PropTypes.string,
  onClose: PropTypes.func,
  show: PropTypes.bool,
}

export default DropdownContentControlled
